<template>
  <v-container class="pl-sm-16" fluid>
    <!-- Stack the columns on mobile by making one full-width and the other half-width -->
    <v-row class="pl-sm-1">
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <v-card class="pa-2 name-card" color="black">
              <div class="text-h6">👋 Hello {{ auth.name }}!</div>

              <div class="px-5 py-3">
                Welcome to the admin panel! This dashboard has been designed to
                help you manage users, auctions, and fishes easily and
                efficiently.
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="user">
            <v-row>
              <v-col cols="6">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="blue lighten-2"
                >
                  <div class="text-h3 text-center">{{ user.total }}</div>
                  <div class="text-center text-caption">Total Users</div>
                </v-card>
              </v-col>

              <v-col cols="3">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="green lighten-2"
                >
                  <div class="text-h3 text-center">{{ user.active }}</div>
                  <div class="text-center text-caption">Active Users</div>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="orange lighten-2"
                >
                  <div class="text-h3 text-center">{{ user.new }}</div>
                  <div class="text-center text-caption">New Users</div>
                </v-card>
              </v-col>
              <!-- <v-col cols="3">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="red lighten-2"
                >
                  <div class="text-h3 text-center">{{ user.deactive }}</div>
                  <div class="text-center text-caption">Deactive Users</div>
                </v-card>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="12" v-if="auction">
            <v-row>
              <v-col cols="6">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card "
                  color="pink lighten-2"
                >
                <v-icon class="aution-icon">mdi-chart-timeline-variant </v-icon>
                  <div class="text-h3 text-center pt-5">{{ auction.total }}</div>
                  <div class="text-center text-caption pb-3">Total Auctions</div>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card
                  height="100%"
                  class="pa-2 white--text user-card"
                  color="purple lighten-2"
                >
                <v-icon class="aution-icon">mdi-chart-timeline-variant-shimmer </v-icon>
                  <div class="text-h3 text-center pt-5">{{ auction.ongoing }}</div>
                  <div class="text-center text-caption pb-3">Active Auctions</div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="pa-2 pa-sm-5">
          <apexchart
            width="100%"
            height="400"
            type="area"
            :options="options"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
      <!-- <v-col v-for="n in 3" :key="n" cols="6" md="4">
        <v-card class="pa-2" outlined tile> .col-6 .col-md-4 </v-card>
      </v-col>

      <v-col v-for="n in 2" :key="n" cols="6">
        <v-card class="pa-2" outlined tile> .col-6 </v-card>
      </v-col> -->
    </v-row>
    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    timeout: 2000,
    options: {
      chart: {
        id: "vuechart-example",
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: "datetime",
      },
      stroke: {
        curve: "smooth",
      },

      dataLabels: {
        enabled: false,
      },
      colors: ["rgba(1, 129, 161, 0.8)", "rgba(12, 186, 189, 0.5)"],
    },
    series: [],
    auction: null,
    user: null,
  }),
  created() {
    //
  },
  mounted() {
    this.getUserCount();
  },
  methods: {
    getAuctions() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/auction/all";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;

        let series_data = Object.entries(
          resp.reduce((acc, data) => {
            const orderDate = this.$moment(data.created_at).startOf(
              "day"
            );
            acc[orderDate] = acc[orderDate] ? acc[orderDate] + 1 : 1;
            return acc;
          }, {})
        ).map(([x, y]) => ({ x: this.$moment(x).valueOf(), y }));

        this.series = [
          {
            name: "Auctions",
            data: series_data,
          },
        ];
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
    getAuctionCount() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/auction/count";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.auction = resp;
        this.getAuctions();
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
    getUserCount() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/user/count";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.user = resp;
        this.getAuctionCount();
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon{
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>